<template>
  <div>
    <b-card class="">
      <div class="mb-1">
        <strong>
          Clients
        </strong>
      </div>
      <b-table
        small
        :fields="fields"
        :items="customers"
        responsive="sm"
        @row-clicked="viewClient"
      >
        <template #cell(phone)="data">
          <div class="notification_view_box">
            <span>{{ data.item.phone }}</span>
            <div
              v-if="data.item.unread_messages !== 0"
              class="main_notify_box"
            >
              <feather-icon
                icon="BellIcon"
                size="22"
              />
              <div class="count_box">
                {{ data.item.unread_messages }}
              </div>

            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="mortgage-file-modal"
      :title="`Borrower: ${client.full_name}`"
      size="xl"
      :centered="false"
      hide-footer
      @close="closeMortgageModal()"
    >

      <b-tabs
        content-class="mt-3"
        class="mortgage-tab"
        v-model="tabIndex"
      >
        <b-tab
          title="Overview"
          active
        >

          <!-- overview content start -->

          <div class="overview-box">

            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box"> <feather-icon
                class="icon-view"
                icon="UserIcon"
                size="25"
              /></div>
              <div class="content-view">
                <h5>Borrower</h5>
                <span>{{ client.full_name }} </span>
              </div>
            </div>
            <!-- overview item end -->

            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box"> <feather-icon
                class="icon-view"
                icon="DollarSignIcon"
                size="25"
              /></div>
              <div class="content-view">
                <h5>Loan Amount</h5>
                <span>{{ formartCurrency(client.amount) }}</span>
              </div>
            </div>
            <!-- overview item end -->
          </div>
          <!-- overview content end -->

          <!-- overview content start -->

          <div class="overview-box">
            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box"> <feather-icon
                class="icon-view"
                icon="AlertCircleIcon"
                size="25"
              /></div>
              <div class="content-view">
                <h5>Status</h5>
                <span>{{ client.status }}</span>
              </div>
            </div>
            <!-- overview item end -->

            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box"> <feather-icon
                class="icon-view"
                icon="CalendarIcon"
                size="25"
              /></div>
              <div class="content-view">
                <h5>Status Date</h5>
                <span>{{ client.date }}</span>
              </div>
            </div>
            <!-- overview item end -->
          </div>
          <!-- overview content end -->

        </b-tab>
        <!-- <b-tab title="Details">
          <p>No details</p>
        </b-tab> -->
        <b-tab title="Discussion">
          <template #title>
            Discussion<div v-if="client.unread_messages !== 0" class="main_notify_box" style="margin-left:10px !important">
              <feather-icon icon="BellIcon" size="22" />
              <div class="count_box">
                {{ client.unread_messages }}
              </div>
            </div>
           </template>
          <Chat v-if="showChats" :mortgage_id="client.mortgage_id"/>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, VBTooltip, BRow, BCol, BCard, BBadge, BModal, BTabs,
  BTab,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import Chat from '@/views/app/chat/Chat.vue'
import bus from '../../../libs/bus'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    HwaButton,
    HwaModal,
    BTable,
    Chat,
    BModal,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast, confirm],
  mixins: [showToast],
  data() {
    return {
      fields: [{key:'full_name',label:"Full Name",sortable:true}, 'email', 'phone'],
      client: {},
      showDetails: false,
      customers:[],
      tabIndex:0,
    }
  },
  destroyed() {
    bus.$off("new-message")
  },
  mounted() {
    bus.$on("new-message",(id)=>{
      this.customers = this.customers.map((m)=>{
        if(+m.mortgage_id===+id){
          m.unread_messages++;
        }
        return m;
      })
    })
    this.getCustomers()
  },
  watch:{
    clients(val){
      this.customers = val
    },
    tabIndex(val){
      if(val===1){
        this.client.unread_messages = 0;
      }
    },
  },
  computed:{
    showChats(){
      return this.tabIndex === 1
    },
    clients(){
      return this.$store.getters["chat/clients"]
    }
  },
  methods: {
    formartCurrency(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      const str = formatter.format(amount)
      const splitStr = str.split('.')
      return splitStr[0]
    },
    closeMortgageModal() {
      this.showDetails = false
    },
    openChats() {
      this.$bvModal.show('open-chat')
    },
    viewClient(client) {
      this.showDetails = true
      this.client = { ...client }
      this.$bvModal.show('mortgage-file-modal')
    },
    getCustomers() {
      this.$http.get('/api/licensees/borrowers')
        .then(res => {
          this.$store.commit("chat/setClients",res.data)
        })
        .catch(err => {
          this.showError('Failed to retrieve customers')
        })
    },
  },
}
</script>
